body {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.card {
    width: 100%;
}

.card__event {
    width: 100%;
    max-width: 525px;
    margin-bottom: 40px;
}

@media (hover: hover) {
    .isPlaying .player:hover {
        background: #EEE;
        cursor: pointer;
    }
}

.player.active {
    /*font-weight: bold;*/
    background: #FFFF99;
}

.player.me, .teamsPlayers .me {
    font-weight: bold;
}

.grayed {
    color: #666;
}

.teamsPlayers {
    padding: 0 0 20px 20px;
}

.player {
    overflow: hidden;
    white-space: nowrap;
}

.card-title {
    background: #FFF;
}

.card__img {
    /*height: 127px;*/
    height: 190px;
    position: relative;
    overflow: hidden;
}

.card__img img {
    height: 190px;
    position: absolute;
    left: 0;
}

@media only screen and (max-width: 600px) {
    .card__img {
        height: 127px;
    }
}


.card__event-type {
    position: relative;
    padding: 0 6px;
    /*display: inline;*/
    /*width: 100%;*/
    /*text-align: center;*/
    /**/
    font-weight: bold;
    font-size: 30px;
    /*color: #333;*/
    background: #FFF;


    color: #333;
    text-shadow: -1px -1px 0 #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
    1px 1px 0 #FFF;
}

.kep-login-facebook.medium {
    height: 50px;
}

.temp-success input, .temp-success .custom-checkbox {
    border-color: #00c800;
    background: #c8ffc8;
}

.input-temp-error {
    border-color: #F00 !important;
    background: #ffc8c8 !important;
}

.form-label-group {
    position: relative;
    margin-bottom: .5rem;
}

.form-label-group input,
.form-label-group label {
    /*   height: 3.125rem;
       padding: .75rem;*/
    height: 2.5rem;
    padding: .45rem;
}

.form-label-group label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    pointer-events: none;
    cursor: text; /* Match the input under the label */
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
    color: transparent;
}

.form-label-group input::-moz-placeholder {
    color: transparent;
}

.form-label-group input:-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::placeholder {
    color: transparent;
}

.form-label-group input:not(:-moz-placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: .25rem;
}

.form-label-group input:not(:-ms-input-placeholder) {
    padding-top: 1.25rem;
    padding-bottom: .25rem;
}

.form-label-group input:not(:placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: .25rem;
}

.form-label-group input:not(:-moz-placeholder-shown) ~ label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 11px;
    color: #777;
}

.form-label-group input:not(:-ms-input-placeholder) ~ label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 11px;
    color: #777;
}

.form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 11px;
    color: #777;
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
    .form-label-group {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }

    .form-label-group label {
        position: static;
    }

    .form-label-group input::-ms-input-placeholder {
        color: #777;
    }
}
